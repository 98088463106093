// AnimatedTriangle.js
import React from 'react';
import styles from '@/components/home/v2/illustrations/AnimatedTriangles.module.css';

const AnimatedTriangle = () => {
  return (
    <div className={styles.triangleContainer}>
      <div className={styles.triangle}></div>
      <div className={styles.triangle}></div>
      <div className={styles.triangle}></div>
    </div>
  );
};

export default AnimatedTriangle;
