import styles from "@/components/home/v2/Footer.module.css"

import ConnectContainer from "@/components/ConnectContainer";
import FooterContainer from "@/components/FooterContainer";
import Link from "next/link";

const Footer = () => {
    return (
        <div className={styles.footer}>
            <ConnectContainer/>
            <FooterContainer/>

            <div className={styles.footer2}>
                <div className={styles.footer2Content}>
                    {
                        "Future Motion Solutions Ltd t/a ClearWatt is a private limited company registered in England and Wales with number 09793739."
                    }
                </div>
                <div className={styles.footer2Content}>
                    <div className={styles.copyright}>{`© 2024 ClearWatt`}</div>
                    <div className={styles.footer2Buttons}>
                        <Link href="/legal/terms-and-conditions" className={styles.link1}>
                            Terms and Conditions
                        </Link>
                        <Link href="/legal/privacy-policy" className={styles.link1}>
                            Privacy Policy
                        </Link>
                        <Link href="/legal/cookies-notice" className={styles.link1}>
                            Cookies Notice
                        </Link>
                        <Link href="/sitemap.xml" className={styles.link1}>
                            Sitemap
                        </Link>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Footer;
