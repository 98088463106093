import styles from "@/components/home/v2/BetaFormSection.module.css"

import useTypewriterOnScroll from "@/utils/useTypewriterOnScroll";
import {useRef} from "react";

import useRevealOnScroll from "@/utils/useRevealOnScroll";
import {motion} from 'framer-motion';
import BetaWizardForm from "@/components/forms/v2/BetaWizardForm";
import ScrollTo from "@/utils/ScrollTo";

function BetaFormSection() {
  const messageRef1 = useRef(null);
  const messageRef2 = useRef(null);

  const title_1 = "BETA Testing Programme"
  const title_2 = "Be the first to get an EV system health report!"

  const messages = [title_1, title_2];
  const refs = [messageRef1, messageRef2];

  const delays = [0, 0];


  //useTypewriterOnScroll(refs, messages, styles, 500);
  useTypewriterOnScroll(refs, messages, styles, delays);


  const descriptionRef = useRef(null);
  const revealRefs = [descriptionRef];
  const revealDelays = [500];
  const {revealedIndices} = useRevealOnScroll(revealRefs, revealDelays);


  return (
    <div className={styles.container} id="beta-program">
      <ScrollTo targetId="beta-program" />
      <div className={styles.content}>


        <div className={styles.contentRow}>


          <div className={styles.col_1}>
            <h3 ref={messageRef1} className={styles.h3}></h3>

            <h2 ref={messageRef2} className={styles.h2}></h2>

            <motion.div ref={descriptionRef}
                        className={styles.description}
                        initial={{opacity: 0, y: 20}}
                        animate={revealedIndices.has(0) ? {opacity: 1, y: 0} : {}}
                        transition={{duration: 0.5}}>
              Get early access to ClearWatt's platform and be the first to test our EV Health Checker.
            </motion.div>

            <motion.div ref={descriptionRef}
                        className={styles.phevWarning}
                        initial={{opacity: 0, y: 20}}
                        animate={revealedIndices.has(0) ? {opacity: 1, y: 0} : {}}
                        transition={{duration: 0.5}}>
              ⚠️ ClearWatt is compatible with pure Battery Electric Vehicles (BEVs) only. Our system is not designed to test Plug-in Hybrid Electric Vehicles (PHEVs).
            </motion.div>


          </div>
          <div className={styles.col_2}>
            {/*<BetaWizardForm />*/}
            <BetaWizardForm audienceType="default"/>

          </div>
        </div>


      </div>
    </div>
  );
}

export default BetaFormSection;
