const Regression = () => {
  return <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="Regression" data-sentry-source-file="Regression.jsx">
      <rect x="1" y="1" width="158" height="158" fill="white" stroke="#05293D" strokeWidth="2" data-sentry-element="rect" data-sentry-source-file="Regression.jsx" />
      <circle cx="47" cy="80" r="7" fill="#05293D" stroke="#05293D" strokeWidth="2" data-sentry-element="circle" data-sentry-source-file="Regression.jsx" />
      <circle cx="86" cy="113" r="7" fill="#17E8DA" stroke="#05293D" strokeWidth="2" data-sentry-element="circle" data-sentry-source-file="Regression.jsx" />
      <circle cx="114" cy="68" r="7" fill="#05293D" stroke="#05293D" strokeWidth="2" data-sentry-element="circle" data-sentry-source-file="Regression.jsx" />
      <line x1="24" y1="28" x2="24" y2="138" stroke="#05293D" strokeWidth="2" data-sentry-element="line" data-sentry-source-file="Regression.jsx" />
      <line x1="133.17" y1="84.9854" x2="33.1702" y2="102.261" stroke="#05293D" strokeWidth="2" data-sentry-element="line" data-sentry-source-file="Regression.jsx" />
      <line x1="133" y1="139" x2="23" y2="139" stroke="#05293D" strokeWidth="2" data-sentry-element="line" data-sentry-source-file="Regression.jsx" />
    </svg>;
};
export default Regression;